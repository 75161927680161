export function useOnlyInBrowser<T>(
  callback: () => T
): [boolean, T | undefined] {
  const isInBrowser = typeof document !== 'undefined';
  let response: T | undefined = undefined;

  if (isInBrowser) {
    response = callback();
  }

  return [isInBrowser, response];
}
