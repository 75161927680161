import { useOnlyInBrowser } from './useOnlyInBrowser';

interface BrowserCapabilities {
  isBrowserOk: boolean;
  sharedArrayBuffer: boolean;
}

type IsInBrowser = boolean;
type HasChecked = boolean;
type IsBrowserOk = boolean | undefined;

/**
 * Check if the browser is compatible with the app. Specifically, we check if SharedArrayBuffer is available.
 *
 * @param doCheck - if true, check browser capabilities. Should not be used without correct headers
 * @returns [isInBrowser, hasChecked, isBrowserOk]
 */
export function useCheckBrowser(
  doCheck: boolean
): [IsInBrowser, HasChecked, IsBrowserOk] {
  let hasChecked = false;

  const [isInBrowser, isBrowserOk] = useOnlyInBrowser(() => {
    const capabilitiesJSON = localStorage.getItem('capabilities');

    if (capabilitiesJSON) {
      hasChecked = true;

      const capabilities = JSON.parse(capabilitiesJSON) as BrowserCapabilities;

      return capabilities.isBrowserOk;
    }

    if (!doCheck) {
      return undefined;
    }

    const sharedArrayBuffer = Boolean(globalThis.SharedArrayBuffer);

    const capabilities = {
      isBrowserOk: sharedArrayBuffer,
      sharedArrayBuffer,
    };

    localStorage.setItem('capabilities', JSON.stringify(capabilities));

    return capabilities.isBrowserOk;
  });

  return [isInBrowser, hasChecked, isBrowserOk];
}
