import type { User } from '../@types/User';
import { json, redirect } from '@remix-run/cloudflare';
import { signInWithCustomToken } from '../firebase.server';
import {
  commitSession,
  getSession,
  SESSION_ERROR,
  SESSION_USER,
} from '../session.server';
import { getVariables } from './loggedInUser/featureFlags';

interface Response {
  isLoggedIn: boolean;
  user: User | null;
  error: string | null;
}

const LOGGED_IN_REDIRECT = '/video';

export const loggedInUser = async ({ request }: { request: Request }) => {
  const session = await getSession(request.headers.get('Cookie'));
  let user: User | undefined = session.get(SESSION_USER);

  if (user) {
    if (user.accessLevel === 'Admin') {
      return redirect(LOGGED_IN_REDIRECT);
    }

    const { isInBeta } = await getVariables(user.uid);

    if (!isInBeta) {
      return redirect('/coming-soon');
    }

    return redirect(LOGGED_IN_REDIRECT);
  }

  if ((globalThis as any).OFFLINE_AUTH) {
    const user: User = {
      uid: 'fake-user',
      accessLevel: 'Admin',
    } as User;

    session.set(SESSION_USER, user);

    return redirect(LOGGED_IN_REDIRECT, {
      headers: {
        'Set-Cookie': await commitSession(session),
      },
    });
  }

  const url = new URL(request.url);

  const token = url.searchParams.get('token');
  const error = url.searchParams.get('error');

  if (!token) {
    return json<Response>({
      isLoggedIn: false,
      user: null,
      error,
    });
  }

  try {
    user = await signInWithCustomToken(token);

    session.set(SESSION_USER, user);

    if (user.accessLevel !== 'Admin') {
      const { isInBeta } = await getVariables(user.uid);

      if (!isInBeta) {
        return redirect('/coming-soon', {
          headers: {
            'Set-Cookie': await commitSession(session),
          },
        });
      }
    }

    return redirect(LOGGED_IN_REDIRECT, {
      headers: {
        'Set-Cookie': await commitSession(session),
      },
    });
  } catch (error) {
    console.error(error);

    const message = 'There was an error loging in. Please try again later';

    session.flash(SESSION_ERROR, message);

    return json<Response>(
      {
        isLoggedIn: false,
        user: null,
        error: message,
      },
      {
        headers: {
          'Set-Cookie': await commitSession(session),
        },
      }
    );
  }
};
